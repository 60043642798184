<template>
  <div class>
    <div class="invalid-token" v-if="!emailVerified">
      <h1>Oops!</h1>
      <h3>
        Looks like that link is no longer valid. Or maybe some of the characters
        are missing. Please check you copied the entire link.
      </h3>
    </div>
    <div class="email-verifier" v-else>
      <h1>Ace!</h1>
      <h3>Your account is now verified</h3>
      <img
        src="@/assets/img/close-dark.svg"
        alt
        class="pointer"
        @click="$router.push({ name: 'events' })"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "TheEmailVerifier",
  data() {
    return {
      emailVerified: false,
    };
  },
  computed: {},
  methods: {
    verifyEmail() {
      this.$axios({
        headers: { Authorization: this.$store.state.user.sessionInfo.token },
        data: {
          query: `
          mutation{
            VerifyEmail(token:"${this.$router.currentRoute.query.token}"){
              id
            }
          }
          `,
        },
      })
        .then((result) => {
          if (result.data.data.VerifyEmail != null) {
            this.emailVerified = true;
          }
        })
        .catch(() => {});
    },
  },
  mounted() {
    this.verifyEmail();
  },
  beforeCreate() {
    this.$store.dispatch("disableEmailVerificationBanner");
  },
  destroyed() {},
};
</script>

<style lang="scss" scoped>
.invalid-token {
  display: flex;
  align-items: center;
  flex-direction: column;

  h1 {
    color: $watermelon;
  }
  h3 {
    color: $watermelon;
  }
}
.email-verifier {
  display: flex;
  align-items: center;
  flex-direction: column;
  img {
    margin-top: 60px;
  }
  h1 {
    color: $avocado;
  }
  h3 {
    color: $avocado;
  }
}
h1 {
  display: block;
  font-size: 100px;
  text-align: center;
  margin-top: 100px;
  margin-bottom: 53px;
  line-height: 121px;
  @include Gilroy-Bold;
}

h3 {
  display: block;
  font-size: 20px;
  text-align: center;
  color: $watermelon;
  @include Gilroy-Bold;
  padding: 0 50px;
}

button {
  margin-top: 60px;
}

@media (min-width: 480px) and (orientation: landscape) {
}

@media (min-width: 544px) {
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
}
</style>
